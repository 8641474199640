import { ReactNode } from 'react';
import styles from './Layout.module.scss';
import { MetaType, SettingsType } from '@/types';
import { PageBackdrop } from '@/components/PageBackdrop';
import Meta from '@/components/Meta';
import { useRouter } from 'next/router';
import { AdminHeader } from './AdminHeader';
import cn from 'classnames';
import dynamic from 'next/dynamic';

const Footer = dynamic(() => import('./Footer').then((mod) => mod.Footer));
const Header = dynamic(() => import('./Header').then((mod) => mod.Header));
const Sidebar = dynamic(() => import('./Sidebar').then((mod) => mod.Sidebar));

type LayoutProps = {
  data: SettingsType;
  /**
   * Pass an empty meta data if not required (constant EMPTY_META)
   */
  meta: MetaType;
  children: ReactNode;
};

export function Layout({
  data,
  meta = { title: 'Остров мечты' },
  children,
}: LayoutProps) {
  const { menuFooter, mode, socials, disclaimer, copyright, address, phone } =
    data;
  const { pathname } = useRouter();
  const isAdminPage = pathname.includes('/moderator');

  return (
    <div className={styles.root}>
      <div
        className={cn(styles.adaptive, {
          [styles.admin]: isAdminPage,
        })}
      >
        {data && !isAdminPage && (
          <Header
            menu={data.menu}
            links={data.links}
            translations={data.translations.common}
          />
        )}
        {isAdminPage && <AdminHeader />}
        {children}
        {data && <Sidebar menu={data.menu} />}
        <PageBackdrop />
      </div>
      {data && (
        <Footer
          menu={menuFooter}
          timeTable={mode}
          socials={socials}
          disclaimer={disclaimer}
          copyright={copyright}
          address={address}
          phone={phone}
        />
      )}
      <Meta
        title={meta.title}
        description={meta.description}
        image={meta.picture}
        canonical={meta.canonical}
        robots={meta.robots}
        keywords={meta.keywords}
        h1={meta.h1}
        text={meta.text}
      />
    </div>
  );
}
