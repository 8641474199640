import cn from 'classnames';
import styles from './AdminHeader.module.scss';
import { useRouter } from 'next/router';
import { deleteToken } from '@/services/deleteToken';
import { useDispatch } from 'react-redux';
import { setUserData } from '@/store';
import { Button } from '@/components/Button';
import { Variants } from '@/types';
import { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY } from '@/constants';

type AdminHeaderProps = {
  className?: string;
};

export function AdminHeader({ className }: AdminHeaderProps) {
  const wrapperClassName = cn(styles.wrapper, className);
  const { push } = useRouter();
  const dispatch = useDispatch();

  const handleLogoClick = () => {
    push('/');
  };

  const handleAdminClick = () => {
    push('/profile');
  };

  const handleLogOutClick = () => {
    deleteToken();
    localStorage.removeItem(ACCESS_TOKEN_KEY);
    localStorage.removeItem(REFRESH_TOKEN_KEY);
    dispatch(setUserData(null));
    push('/');
  };

  return (
    <div className={wrapperClassName}>
      <img
        src="/svg/logo-dark.svg"
        alt="logo"
        className={styles.logo}
        onClick={handleLogoClick}
      />
      <div className={styles.actions}>
        <div className={styles.admin} onClick={handleAdminClick}>
          <img
            src="/svg/profile-dark.svg"
            alt="avatar"
            className={styles.avatar}
          />
          <div className={styles.name}>Модератор</div>
        </div>
        <Button
          text="Выйти"
          variant={Variants.Ghost}
          onClick={handleLogOutClick}
          className={styles.logOut}
        />
      </div>
    </div>
  );
}
