import cn from 'classnames';
import styles from './PageBackdrop.module.scss';
import { useSelector } from 'react-redux';
import { selectSearchActive } from '@/store';
import { useWindowWidth } from '@/hooks/useWindowWidth';
import { TABLET_RESOLUTION } from '@/constants';

type PageBackdropProps = {
  className?: string;
};

export function PageBackdrop({ className }: PageBackdropProps) {
  const searchActive = useSelector(selectSearchActive);
  const width = useWindowWidth();

  const wrapperClassName = cn(
    styles.wrapper,
    {
      [styles.active]: searchActive && width && width < TABLET_RESOLUTION,
    },
    className,
  );

  return <div className={wrapperClassName} />;
}
